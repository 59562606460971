import React from "react";
import PropTypes from "prop-types";

const BlocksTabIcon = ({className}) => {
	return (
		<svg className={className} width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M16.4375 1.58801L12.2344 0.03125L8.03125 1.58801V6.92163L11.625 8.25269V9.97815L10.0938 10.5736V11.9148L12.875 10.8331V8.24109L16.4375 6.92163V1.58801ZM14.0129 2.02295L12.2344 2.68176L10.4558 2.02295L12.2344 1.36426L14.0129 2.02295ZM9.28125 6.05164V2.9209L11.625 3.78894V6.91968L9.28125 6.05164ZM12.875 6.90808V3.77747L15.1875 2.9209V6.05164L12.875 6.90808ZM5.28125 6.08582L6.78125 5.5127V4.17456L4.03125 5.22534V7.8009L0.4375 9.13196V14.4657L4.64062 16.0223L8.84375 14.4656V9.13196L5.28125 7.8125V6.08582ZM4.64062 10.2257L2.86206 9.56702L4.64062 8.9082L6.41919 9.56702L4.64062 10.2257ZM1.6875 10.465L4.03125 11.333V14.4637L1.6875 13.5957V10.465ZM5.28125 14.4521V11.3214L7.59375 10.465V13.5957L5.28125 14.4521Z'
				fill='currentColor'
			/>
		</svg>
	);
};

BlocksTabIcon.propTypes = {
	className: PropTypes.string,
};

BlocksTabIcon.defaultProps = {
	className: "",
};
export default BlocksTabIcon;
