import React from "react";
import PropTypes from "prop-types";

const OracleScriptsTabIcon = ({className}) => {
	return (
		<svg className={className} width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0)'>
				<path
					d='M3.17808 16C3.11341 16 3.04875 15.9873 2.98675 15.962C2.80008 15.8847 2.67808 15.702 2.67808 15.5V12.6667H1.84475C0.833414 12.6667 0.0114136 11.8447 0.0114136 10.8333V1.83333C0.0114136 0.822 0.833414 0 1.84475 0H14.1781C15.1894 0 16.0114 0.822 16.0114 1.83333V10.8333C16.0114 11.8447 15.1894 12.6667 14.1781 12.6667H6.71875L3.53208 15.8533C3.43608 15.9493 3.30808 16 3.17808 16ZM1.84475 1C1.38541 1 1.01141 1.374 1.01141 1.83333V10.8333C1.01141 11.2927 1.38541 11.6667 1.84475 11.6667H3.17808C3.45408 11.6667 3.67808 11.8907 3.67808 12.1667V14.2927L6.15808 11.8127C6.25208 11.7187 6.37875 11.666 6.51141 11.666H14.1781C14.6374 11.666 15.0114 11.292 15.0114 10.8327V1.83333C15.0114 1.374 14.6374 1 14.1781 1H1.84475Z'
					fill='currentColor'
				/>
				<path
					d='M5.67809 8.49995C5.56476 8.49995 5.45143 8.46195 5.3581 8.38395L3.3581 6.71729C3.2441 6.62263 3.1781 6.48196 3.1781 6.33329C3.1781 6.18463 3.2441 6.04396 3.3581 5.94929L5.3581 4.28263C5.5701 4.10597 5.88476 4.1333 6.06209 4.34663C6.23943 4.55863 6.21076 4.87396 5.99809 5.05063L4.45943 6.33329L5.99876 7.61596C6.21076 7.79262 6.23943 8.10795 6.06276 8.31995C5.96343 8.43862 5.82143 8.49995 5.67809 8.49995Z'
					fill='currentColor'
				/>
				<path
					d='M9.67807 8.49995C9.53474 8.49995 9.39274 8.43862 9.29407 8.31995C9.11674 8.10795 9.14541 7.79262 9.35807 7.61596L10.8967 6.33329L9.35807 5.05063C9.14607 4.87396 9.11741 4.55863 9.29407 4.34663C9.47074 4.1333 9.78607 4.10597 9.99874 4.28263L11.9987 5.94929C12.1127 6.04463 12.1787 6.18529 12.1787 6.33329C12.1787 6.48129 12.1127 6.62263 11.9987 6.71729L9.99874 8.38395C9.90474 8.46195 9.7914 8.49995 9.67807 8.49995Z'
					fill='currentColor'
				/>
				<path
					d='M7.01143 9.16675C6.96676 9.16675 6.9201 9.16074 6.8741 9.14741C6.60876 9.07141 6.45476 8.79474 6.53076 8.52941L7.8641 3.86275C7.93944 3.59808 8.2121 3.44274 8.48211 3.51941C8.74744 3.59541 8.90144 3.87208 8.82544 4.13741L7.4921 8.80408C7.42943 9.02408 7.22943 9.16675 7.01143 9.16675Z'
					fill='currentColor'
				/>
			</g>
			<defs>
				<clipPath id='clip0'>
					<rect width='16' height='16' fill='white' transform='translate(0.0114136)' />
				</clipPath>
			</defs>
		</svg>
	);
};

OracleScriptsTabIcon.propTypes = {
	className: PropTypes.string,
};

OracleScriptsTabIcon.defaultProps = {
	className: "",
};
export default OracleScriptsTabIcon;
