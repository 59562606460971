import React from "react";
import PropTypes from "prop-types";

const CoinMarketCapIcon = ({className}) => {
	return (
		<svg className={className} width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M17.134 11.9506C16.9781 12.058 16.796 12.1214 16.607 12.1341C16.4181 12.1468 16.2292 12.1083 16.0603 12.0227C15.6637 11.7986 15.45 11.2733 15.45 10.5575V8.36359C15.45 7.31042 15.0328 6.56109 14.335 6.35767C13.1557 6.01262 12.275 7.45719 11.9351 8.00051L9.84936 11.3763V7.25634C9.82619 6.30617 9.51719 5.73709 8.93266 5.56714C8.54641 5.45384 7.96704 5.50019 7.40312 6.36024L2.7398 13.8458C2.11932 12.6587 1.7976 11.3382 1.8025 9.99871C1.8025 5.48732 5.40749 1.81795 9.84936 1.81795C14.2912 1.81795 17.9091 5.48732 17.9091 9.99871V10.0219C17.9091 10.0219 17.9091 10.0373 17.9091 10.0451C17.9529 10.918 17.6696 11.6132 17.1366 11.9506H17.134ZM19.709 10.0013V9.95751C19.673 4.45989 15.2646 0 9.84936 0C4.43414 0 0 4.48565 0 9.99871C0 15.5118 4.41869 20 9.84936 20C12.3401 19.9999 14.7361 19.0452 16.5444 17.3323C16.7199 17.1669 16.8232 16.9391 16.8318 16.6981C16.8405 16.4571 16.7539 16.2224 16.5907 16.0448C16.5126 15.9584 16.4182 15.8884 16.313 15.8386C16.2077 15.7888 16.0936 15.7604 15.9773 15.7549C15.861 15.7494 15.7447 15.7669 15.6352 15.8065C15.5257 15.8461 15.4252 15.907 15.3393 15.9856C14.5607 16.7241 13.641 17.2976 12.6353 17.6718C11.6296 18.046 10.5587 18.2131 9.48676 18.1631C8.41487 18.113 7.36413 17.8469 6.39765 17.3807C5.43116 16.9145 4.56885 16.2578 3.8625 15.45L8.05974 8.70349V11.8167C8.05974 13.3127 8.63911 13.7968 9.12579 13.9385C9.61246 14.0801 10.3566 13.9822 11.1369 12.7153L13.4544 8.96356C13.5265 8.84254 13.596 8.73954 13.6578 8.64942V10.5575C13.6578 11.9557 14.2191 13.0733 15.2028 13.6243C15.6501 13.8658 16.1541 13.9824 16.662 13.962C17.1698 13.9416 17.6629 13.785 18.0894 13.5084C19.1709 12.8055 19.7605 11.5334 19.6987 10.0013H19.709Z'
				fill='currentColor'
			/>
		</svg>
	);
};

CoinMarketCapIcon.propTypes = {
	className: PropTypes.string,
};

CoinMarketCapIcon.defaultProps = {
	className: "",
};

export default CoinMarketCapIcon;
