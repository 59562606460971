import React from "react";
import PropTypes from "prop-types";

const GitHubIcon = ({className}) => {
	return (
		<svg className={className} width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M9.81818 0.0255127C4.39364 0.0255127 0 4.34551 0 9.67351C0 13.937 2.81291 17.5526 6.71318 18.8273C7.20409 18.9181 7.38409 18.6195 7.38409 18.3634C7.38409 18.1343 7.37591 17.5272 7.37182 16.7229C4.64073 17.3047 4.06473 15.4286 4.06473 15.4286C3.618 14.315 2.97245 14.0172 2.97245 14.0172C2.08309 13.4191 3.04118 13.4314 3.04118 13.4314C4.02709 13.4985 4.545 14.4255 4.545 14.4255C5.42045 15.9007 6.84327 15.4744 7.40455 15.2281C7.49291 14.6038 7.74573 14.1792 8.02636 13.9378C5.84591 13.6965 3.55418 12.8668 3.55418 9.17033C3.55418 8.11733 3.93464 7.2566 4.56464 6.5816C4.45418 6.33779 4.12282 5.35679 4.65055 4.02806C4.65055 4.02806 5.47282 3.76951 7.35055 5.01724C8.136 4.80288 8.97055 4.69651 9.80509 4.6916C10.6396 4.69651 11.4742 4.80288 12.2596 5.01724C14.1251 3.76951 14.9474 4.02806 14.9474 4.02806C15.4751 5.35679 15.1437 6.33779 15.0455 6.5816C15.6715 7.2566 16.0519 8.11733 16.0519 9.17033C16.0519 12.8767 13.7569 13.6924 11.5724 13.9297C11.916 14.2193 12.2351 14.8108 12.2351 15.7149C12.2351 17.006 12.2228 18.0435 12.2228 18.3568C12.2228 18.6097 12.3946 18.9116 12.8978 18.815C16.8259 17.5485 19.6364 13.9305 19.6364 9.67351C19.6364 4.34551 15.2403 0.0255127 9.81818 0.0255127Z'
				fill='white'
			/>
		</svg>
	);
};

GitHubIcon.propTypes = {
	className: PropTypes.string,
};

GitHubIcon.defaultProps = {
	className: "",
};

export default GitHubIcon;
