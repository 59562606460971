import React from "react";
import PropTypes from "prop-types";

const CoinGeckoIcon = ({className}) => {
	return (
		<svg className={className} width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_5131_14494)'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M16.1332 3.81283C14.4923 2.18628 12.2725 1.27815 9.96204 1.28822C8.23899 1.29573 6.55687 1.81402 5.12838 2.77754C3.6999 3.74107 2.58922 5.10655 1.9368 6.70132C1.28437 8.29609 1.11951 10.0485 1.46306 11.737C1.8066 13.4255 2.64313 14.9741 3.86685 16.1872C5.09057 17.4002 6.64652 18.2231 8.33794 18.5518C10.0294 18.8806 11.7803 18.7004 13.3692 18.034C14.9582 17.3676 16.3139 16.245 17.2649 14.8081C18.2159 13.3712 18.7194 11.6846 18.7118 9.96157C18.7016 7.65114 17.774 5.43939 16.1332 3.81283ZM9.95643 9.50756e-05C12.6085 -0.0114615 15.1565 1.03094 17.04 2.898C18.9235 4.76506 19.9882 7.30384 19.9999 9.95589C20.0086 11.9337 19.4307 13.8697 18.3391 15.519C17.2475 17.1684 15.6914 18.457 13.8674 19.2219C12.0435 19.9868 10.0337 20.1937 8.09218 19.8163C6.15068 19.439 4.36466 18.4944 2.96 17.102C1.55535 15.7096 0.59513 13.9319 0.200785 11.9938C-0.193559 10.0557 -0.00432032 8.04415 0.74457 6.21358C1.49346 4.38301 2.76837 2.81562 4.40807 1.70963C6.04777 0.603636 7.97861 0.00871364 9.95643 9.50756e-05Z'
					fill='white'
				/>
				<path
					d='M14.3897 6.97547C13.7619 6.79378 13.1111 6.53615 12.4521 6.27581C12.4141 6.11039 12.2677 5.90429 11.9714 5.65276C11.5409 5.2792 10.7321 5.2887 10.0331 5.45412C9.26155 5.27242 8.49953 5.20802 7.76799 5.38632C1.7849 7.03446 5.17746 11.0555 2.98083 15.0975C3.29338 15.7606 6.66222 19.629 11.5368 18.5911C11.5368 18.5911 9.86969 14.585 13.6318 12.6623C16.6833 11.0968 18.8881 8.20056 14.3897 6.97547Z'
					fill='white'
				/>
				<path
					d='M15.1272 9.60702C15.1276 9.67876 15.1068 9.749 15.0674 9.80887C15.028 9.86874 14.9718 9.91555 14.9058 9.94337C14.8399 9.97119 14.7672 9.97878 14.697 9.96518C14.6268 9.95158 14.5622 9.9174 14.5113 9.86696C14.4604 9.81652 14.4256 9.75209 14.4113 9.68181C14.3969 9.61153 14.4037 9.53856 14.4307 9.47213C14.4577 9.4057 14.5037 9.34879 14.563 9.3086C14.6222 9.26841 14.692 9.24675 14.7635 9.24634C14.811 9.24608 14.8581 9.25519 14.9021 9.27317C14.9461 9.29116 14.9861 9.31765 15.0199 9.35114C15.0536 9.38463 15.0805 9.42447 15.0989 9.46837C15.1173 9.51228 15.1269 9.55939 15.1272 9.60702Z'
					fill='#0A0F1E'
				/>
				<path d='M10.0334 5.45126C10.4697 5.48245 12.0473 5.99363 12.4544 6.27295C12.1152 5.2899 10.9732 5.15906 10.0334 5.45126Z' fill='white' />
				<path
					d='M10.4481 7.93792C10.4483 8.26889 10.3503 8.59247 10.1667 8.86772C9.98308 9.14297 9.72202 9.35754 9.41655 9.48428C9.11107 9.61102 8.77491 9.64424 8.45057 9.57974C8.12623 9.51524 7.82829 9.35591 7.59443 9.12192C7.36057 8.88792 7.2013 8.58976 7.13676 8.26516C7.07222 7.94055 7.10532 7.60408 7.23186 7.2983C7.35841 6.99253 7.57271 6.73118 7.84767 6.54732C8.12263 6.36346 8.4459 6.26534 8.77657 6.26538C9.2198 6.26544 9.64486 6.44165 9.95831 6.75529C10.2718 7.06893 10.4479 7.49431 10.4481 7.93792Z'
					fill='#0A0F1E'
				/>
				<path
					d='M9.95288 7.9527C9.95288 8.18548 9.88382 8.41303 9.75445 8.60658C9.62508 8.80013 9.4412 8.95098 9.22607 9.04006C9.01093 9.12914 8.7742 9.15245 8.54582 9.10704C8.31743 9.06163 8.10764 8.94953 7.94299 8.78493C7.77833 8.62033 7.6662 8.41062 7.62077 8.18232C7.57534 7.95401 7.59865 7.71736 7.68777 7.50231C7.77688 7.28725 7.92778 7.10343 8.1214 6.97411C8.31502 6.84478 8.54265 6.77576 8.77551 6.77576C9.08777 6.77576 9.38724 6.89976 9.60803 7.12048C9.82883 7.3412 9.95288 7.64056 9.95288 7.9527Z'
					fill='white'
				/>
				<path
					d='M16.4802 10.3441C15.1245 11.2994 13.5831 12.0241 11.3964 12.0241C10.3729 12.0241 10.1655 10.9394 9.48897 11.4695C9.13988 11.7448 7.90893 12.3604 6.93216 12.3136C5.95539 12.2668 4.37196 11.6939 3.93068 9.60852C3.7558 11.6939 3.66633 13.2302 2.88342 14.9909C4.44245 17.4878 8.15973 19.4139 11.534 18.5841C11.1714 16.0519 13.3838 13.5719 14.6304 12.3028C15.1049 11.8221 16.0064 11.0377 16.4802 10.3428V10.3441Z'
					fill='white'
				/>
				<path
					d='M16.434 10.4068C16.0129 10.7905 15.5119 11.0746 15.002 11.3254C14.4859 11.5704 13.9479 11.7663 13.395 11.9105C12.8418 12.0535 12.2648 12.1613 11.6803 12.1085C11.0958 12.0556 10.4985 11.8569 10.1113 11.419L10.1296 11.3979C10.6043 11.7057 11.1521 11.8142 11.6972 11.8298C12.2498 11.8466 12.8025 11.8011 13.3449 11.6942C13.8927 11.5795 14.4287 11.4138 14.9457 11.1993C15.463 10.9857 15.9749 10.7383 16.4157 10.3857L16.434 10.4068Z'
					fill='#0A0F1E'
				/>
			</g>
			<defs>
				<clipPath id='clip0_5131_14494'>
					<rect width='20' height='20' fill='currentColor' />
				</clipPath>
			</defs>
		</svg>
	);
};

CoinGeckoIcon.propTypes = {
	className: PropTypes.string,
};

CoinGeckoIcon.defaultProps = {
	className: "",
};

export default CoinGeckoIcon;
