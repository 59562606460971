import React from "react";
import PropTypes from "prop-types";

const WalletIcon = ({className}) => {
	return (
		<svg className={className} width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14.8334 3.83333H14V3C14 2.33696 13.7366 1.70107 13.2678 1.23223C12.7989 0.763392 12.1631 0.5 11.5 0.5H3.16669C2.50365 0.5 1.86776 0.763392 1.39892 1.23223C0.930079 1.70107 0.666687 2.33696 0.666687 3V13C0.666687 13.663 0.930079 14.2989 1.39892 14.7678C1.86776 15.2366 2.50365 15.5 3.16669 15.5H14.8334C15.4964 15.5 16.1323 15.2366 16.6011 14.7678C17.07 14.2989 17.3334 13.663 17.3334 13V6.33333C17.3334 5.67029 17.07 5.03441 16.6011 4.56557C16.1323 4.09673 15.4964 3.83333 14.8334 3.83333ZM3.16669 2.16667H11.5C11.721 2.16667 11.933 2.25446 12.0893 2.41074C12.2456 2.56702 12.3334 2.77899 12.3334 3V3.83333H3.16669C2.94567 3.83333 2.73371 3.74554 2.57743 3.58926C2.42115 3.43298 2.33335 3.22101 2.33335 3C2.33335 2.77899 2.42115 2.56702 2.57743 2.41074C2.73371 2.25446 2.94567 2.16667 3.16669 2.16667ZM15.6667 10.5H14.8334C14.6123 10.5 14.4004 10.4122 14.2441 10.2559C14.0878 10.0996 14 9.88768 14 9.66667C14 9.44565 14.0878 9.23369 14.2441 9.07741C14.4004 8.92113 14.6123 8.83333 14.8334 8.83333H15.6667V10.5ZM15.6667 7.16667H14.8334C14.1703 7.16667 13.5344 7.43006 13.0656 7.8989C12.5967 8.36774 12.3334 9.00362 12.3334 9.66667C12.3334 10.3297 12.5967 10.9656 13.0656 11.4344C13.5344 11.9033 14.1703 12.1667 14.8334 12.1667H15.6667V13C15.6667 13.221 15.5789 13.433 15.4226 13.5893C15.2663 13.7455 15.0544 13.8333 14.8334 13.8333H3.16669C2.94567 13.8333 2.73371 13.7455 2.57743 13.5893C2.42115 13.433 2.33335 13.221 2.33335 13V5.35833C2.60108 5.45251 2.88288 5.50042 3.16669 5.5H14.8334C15.0544 5.5 15.2663 5.5878 15.4226 5.74408C15.5789 5.90036 15.6667 6.11232 15.6667 6.33333V7.16667Z'
				fill='currentColor'
			/>
		</svg>
	);
};

WalletIcon.propTypes = {
	className: PropTypes.string,
};

WalletIcon.defaultProps = {
	className: "",
};

export default WalletIcon;
