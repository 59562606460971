import React from "react";
import PropTypes from "prop-types";

const DashBoardTabIcon = ({className}) => {
	return (
		<svg className={className} width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M16 7.99634C16 9.75147 15.4414 11.4175 14.3846 12.8146C14.1761 13.0903 13.7841 13.1438 13.5092 12.9359C13.2339 12.7277 13.1796 12.3357 13.3877 12.0604C14.2789 10.8823 14.75 9.47693 14.75 7.99634C14.75 4.26563 11.7286 1.25 8 1.25C4.2688 1.25 1.25 4.26794 1.25 7.99634C1.25 9.47693 1.72107 10.8823 2.61218 12.0604C2.82043 12.3357 2.76611 12.7277 2.49084 12.9359C2.21545 13.1442 1.82361 13.0898 1.61523 12.8146C0.558594 11.4175 0 9.75147 0 7.99634C0 3.57446 3.58057 0 8 0C12.4219 0 16 3.5769 16 7.99634ZM11.9601 4.2323C12.2041 4.47632 12.2041 4.87207 11.9601 5.11609L9.92285 7.15332C10.0906 7.46253 10.186 7.81653 10.186 8.19226C10.186 9.39771 9.20532 10.3783 8 10.3783C6.79456 10.3783 5.81396 9.39771 5.81396 8.19226C5.81396 6.98694 6.79456 6.00623 8 6.00623C8.37585 6.00623 8.72974 6.10169 9.03894 6.26941L11.0762 4.23218C11.3203 3.98816 11.7159 3.98816 11.9601 4.2323ZM8.93604 8.19238C8.93604 7.67627 8.51611 7.25635 8 7.25635C7.48389 7.25635 7.06396 7.67627 7.06396 8.19238C7.06396 8.7085 7.48389 9.12842 8 9.12842C8.51611 9.12842 8.93604 8.7085 8.93604 8.19238Z'
				fill='currentColor'
			/>
		</svg>
	);
};

DashBoardTabIcon.propTypes = {
	className: PropTypes.string,
};

DashBoardTabIcon.defaultProps = {
	className: "",
};
export default DashBoardTabIcon;
