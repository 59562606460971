import React from "react";
import PropTypes from "prop-types";

const TransactionsTabIcon = ({className}) => {
	return (
		<svg className={className} width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M13.5475 0H4.20203C3.83912 0 3.54492 0.294196 3.54492 0.657106V14.0078C3.54492 14.3708 3.83912 14.6649 4.20203 14.6649C4.56494 14.6649 4.85913 14.3708 4.85913 14.0078V1.31421H12.8904V13.3507H12.2125C11.8496 13.3507 11.5554 13.6449 11.5554 14.0078C11.5554 14.3708 11.8496 14.6649 12.2125 14.6649H13.5475C13.9105 14.6649 14.2047 14.3708 14.2047 14.0078V0.657106C14.2047 0.294196 13.9104 0 13.5475 0Z'
				fill='currentColor'
			/>
			<path
				d='M10.728 14.4114C10.9124 14.1751 10.9146 13.8434 10.728 13.6042C10.6858 13.5501 10.8123 13.6806 9.33973 12.2081C9.08311 11.9515 8.66704 11.9515 8.41042 12.2081C8.15384 12.4647 8.15384 12.8808 8.41042 13.1374L8.62376 13.3507H6.20496C5.84205 13.3507 5.54785 13.6449 5.54785 14.0078C5.54785 14.3707 5.84205 14.6649 6.20496 14.6649H8.62376L8.41042 14.8782C7.99523 15.2934 8.29502 16 8.87509 15.9999C9.04325 15.9999 9.21144 15.9358 9.33976 15.8075C10.8242 14.3231 10.6885 14.462 10.728 14.4114Z'
				fill='currentColor'
			/>
			<path
				d='M10.21 3.98414C10.573 3.98414 10.8671 3.68994 10.8671 3.32703C10.8671 2.96412 10.573 2.66992 10.21 2.66992H7.53992C7.17701 2.66992 6.88281 2.96412 6.88281 3.32703C6.88281 3.68994 7.17701 3.98414 7.53992 3.98414H10.21Z'
				fill='currentColor'
			/>
			<path
				d='M10.8772 5.34033H6.87195C6.50904 5.34033 6.21484 5.63453 6.21484 5.99744C6.21484 6.36035 6.50904 6.65455 6.87195 6.65455H10.8772C11.2401 6.65455 11.5343 6.36035 11.5343 5.99744C11.5343 5.63453 11.24 5.34033 10.8772 5.34033Z'
				fill='currentColor'
			/>
		</svg>
	);
};

TransactionsTabIcon.propTypes = {
	className: PropTypes.string,
};

TransactionsTabIcon.defaultProps = {
	className: "",
};
export default TransactionsTabIcon;
