import React from "react";
import PropTypes from "prop-types";

const SunIcon = ({className}) => {
	return (
		<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M9.9921 4.68826C8.94115 4.68807 7.91375 4.99953 7.03982 5.58326C6.16589 6.16699 5.48469 6.99677 5.08237 7.96766C4.68005 8.93855 4.57468 10.0069 4.77959 11.0377C4.98449 12.0685 5.49047 13.0154 6.23352 13.7586C6.97658 14.5018 7.92335 15.0079 8.95409 15.2131C9.98482 15.4182 11.0532 15.313 12.0242 14.9109C12.9952 14.5088 13.8251 13.8277 14.409 12.9539C14.9929 12.0801 15.3046 11.0528 15.3046 10.0018C15.3032 8.59321 14.743 7.24269 13.7471 6.24655C12.7511 5.25041 11.4007 4.69 9.9921 4.68826Z'
				fill='#FFEB83'
			/>
			<path
				d='M18.4381 9.06418H17.1892C16.9405 9.06418 16.702 9.16297 16.5262 9.33881C16.3503 9.51466 16.2515 9.75315 16.2515 10.0018C16.2515 10.2505 16.3503 10.489 16.5262 10.6649C16.702 10.8407 16.9405 10.9395 17.1892 10.9395H18.4381C18.6868 10.9395 18.9253 10.8407 19.1012 10.6649C19.277 10.489 19.3758 10.2505 19.3758 10.0018C19.3758 9.75315 19.277 9.51466 19.1012 9.33881C18.9253 9.16297 18.6868 9.06418 18.4381 9.06418Z'
				fill='#FFEB83'
			/>
			<path
				d='M9.9921 16.2495C9.74359 16.2499 9.50538 16.3488 9.32971 16.5246C9.15404 16.7004 9.05527 16.9387 9.05505 17.1872V18.4373C9.05505 18.6858 9.15377 18.9241 9.32948 19.0999C9.5052 19.2756 9.74352 19.3743 9.99202 19.3743C10.2405 19.3743 10.4788 19.2756 10.6546 19.0999C10.8303 18.9241 10.929 18.6858 10.929 18.4373V17.1872C10.9288 16.9387 10.8301 16.7004 10.6544 16.5246C10.4788 16.3488 10.2406 16.2499 9.9921 16.2495Z'
				fill='#FFEB83'
			/>
			<path
				d='M3.74786 10.0018C3.74765 9.75332 3.64888 9.51504 3.47321 9.33926C3.29754 9.16348 3.05933 9.06455 2.81082 9.06418H1.56205C1.31336 9.06418 1.07487 9.16297 0.899023 9.33881C0.723178 9.51466 0.62439 9.75315 0.62439 10.0018C0.62439 10.2505 0.723178 10.489 0.899023 10.6649C1.07487 10.8407 1.31336 10.9395 1.56205 10.9395H2.81082C3.05933 10.9391 3.29755 10.8402 3.47321 10.6644C3.64888 10.4886 3.74765 10.2503 3.74786 10.0018Z'
				fill='#FFEB83'
			/>
			<path
				d='M9.99207 3.75045C10.2406 3.75008 10.4788 3.65115 10.6545 3.47537C10.8301 3.29959 10.9289 3.06132 10.9291 2.81281V1.56265C10.9291 1.31415 10.8304 1.07583 10.6547 0.900117C10.479 0.724402 10.2406 0.625687 9.99214 0.625687C9.74364 0.625687 9.50532 0.724402 9.32961 0.900117C9.15389 1.07583 9.05518 1.31415 9.05518 1.56265V2.81281C9.05536 3.0613 9.1541 3.29958 9.32974 3.47536C9.50538 3.65114 9.74357 3.75007 9.99207 3.75045Z'
				fill='#FFEB83'
			/>
			<path
				d='M15.7381 5.581L16.622 4.69702C16.7093 4.61007 16.7786 4.50677 16.8259 4.39302C16.8732 4.27927 16.8977 4.1573 16.8978 4.0341C16.8979 3.9109 16.8737 3.78889 16.8266 3.67504C16.7796 3.5612 16.7105 3.45776 16.6234 3.37064C16.5363 3.28353 16.4328 3.21445 16.319 3.16735C16.2051 3.12026 16.0831 3.09609 15.9599 3.09621C15.8367 3.09633 15.7147 3.12074 15.601 3.16806C15.4872 3.21537 15.3839 3.28465 15.297 3.37194L14.413 4.25594C14.3257 4.34289 14.2564 4.44619 14.2091 4.55994C14.1618 4.67369 14.1374 4.79566 14.1373 4.91886C14.1371 5.04206 14.1613 5.16407 14.2084 5.27792C14.2555 5.39176 14.3246 5.4952 14.4117 5.58232C14.4988 5.66943 14.6023 5.73851 14.7161 5.7856C14.8299 5.83269 14.952 5.85686 15.0752 5.85674C15.1984 5.85662 15.3203 5.8322 15.4341 5.78489C15.5478 5.73757 15.6511 5.66829 15.7381 5.581Z'
				fill='#FFEB83'
			/>
			<path
				d='M15.7379 14.4228C15.651 14.3355 15.5477 14.2662 15.4339 14.219C15.3202 14.1717 15.1982 14.1473 15.0751 14.1472C14.9519 14.147 14.8299 14.1712 14.7161 14.2183C14.6022 14.2654 14.4988 14.3345 14.4117 14.4216C14.3246 14.5087 14.2555 14.6121 14.2084 14.7259C14.1613 14.8398 14.1372 14.9618 14.1373 15.085C14.1374 15.2081 14.1618 15.3301 14.2091 15.4438C14.2564 15.5576 14.3256 15.6609 14.4129 15.7478L15.2969 16.6318C15.3838 16.7191 15.4871 16.7883 15.6008 16.8356C15.7146 16.8829 15.8365 16.9073 15.9597 16.9074C16.0829 16.9075 16.2049 16.8833 16.3187 16.8362C16.4326 16.7892 16.536 16.7201 16.6231 16.633C16.7102 16.5459 16.7793 16.4425 16.8263 16.3286C16.8734 16.2148 16.8976 16.0928 16.8975 15.9696C16.8974 15.8464 16.873 15.7245 16.8257 15.6107C16.7784 15.497 16.7092 15.3937 16.6219 15.3067L15.7379 14.4228Z'
				fill='#FFEB83'
			/>
			<path
				d='M4.24609 14.4226L3.36211 15.3067C3.27479 15.3936 3.20549 15.4969 3.15815 15.6107C3.11081 15.7244 3.08638 15.8464 3.08624 15.9696C3.08611 16.0928 3.11028 16.2148 3.15737 16.3287C3.20446 16.4426 3.27355 16.546 3.36067 16.6332C3.4478 16.7203 3.55125 16.7894 3.66511 16.8365C3.77897 16.8835 3.901 16.9077 4.02422 16.9076C4.14743 16.9074 4.26941 16.883 4.38317 16.8357C4.49692 16.7883 4.60023 16.719 4.68717 16.6317L5.57117 15.7477C5.65842 15.6608 5.72768 15.5575 5.77497 15.4437C5.82226 15.33 5.84666 15.208 5.84676 15.0848C5.84687 14.9617 5.82269 14.8397 5.7756 14.7258C5.72851 14.612 5.65943 14.5086 5.57233 14.4215C5.48523 14.3344 5.3818 14.2653 5.26798 14.2182C5.15415 14.1711 5.03215 14.1469 4.90897 14.147C4.78579 14.1472 4.66383 14.1716 4.55009 14.2188C4.43635 14.2661 4.33304 14.3354 4.24609 14.4226Z'
				fill='#FFEB83'
			/>
			<path
				d='M4.24615 5.58091C4.33313 5.66803 4.43643 5.73716 4.55014 5.78435C4.66385 5.83153 4.78574 5.85585 4.90886 5.8559C5.03197 5.85595 5.15388 5.83174 5.26763 5.78465C5.38138 5.73756 5.48474 5.66852 5.57179 5.58147C5.65884 5.49441 5.72789 5.39106 5.77497 5.27731C5.82206 5.16356 5.84627 5.04164 5.84622 4.91853C5.84617 4.79542 5.82185 4.67353 5.77467 4.55982C5.72748 4.44611 5.65835 4.34281 5.57123 4.25583L4.68723 3.37183C4.60031 3.28443 4.497 3.21503 4.38322 3.16762C4.26943 3.12021 4.14741 3.09572 4.02415 3.09555C3.90088 3.09538 3.7788 3.11953 3.66488 3.16662C3.55097 3.21372 3.44747 3.28282 3.36031 3.36999C3.27314 3.45715 3.20404 3.56065 3.15695 3.67457C3.10986 3.78848 3.0857 3.91057 3.08588 4.03383C3.08605 4.1571 3.11054 4.27912 3.15795 4.3929C3.20536 4.50668 3.27476 4.60999 3.36216 4.69691L4.24615 5.58091Z'
				fill='#FFEB83'
			/>
		</svg>
	);
};

SunIcon.propTypes = {
	className: PropTypes.string,
};

SunIcon.defaultProps = {
	className: "",
};

export default SunIcon;
