import React from "react";
import PropTypes from "prop-types";

const TwitterIcon = ({className}) => {
	return (
		<svg className={className} width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M13.6668 1.86681C13.1657 2.0842 12.6358 2.22792 12.0935 2.29348C12.6656 1.95169 13.0944 1.414 13.3002 0.780143C12.7626 1.10018 12.174 1.32567 11.5602 1.44681C11.1498 1.00186 10.6035 0.70567 10.0067 0.60471C9.40996 0.503743 8.7965 0.60371 8.2627 0.88893C7.72883 1.17416 7.30476 1.6285 7.05696 2.1807C6.80916 2.7329 6.7517 3.35174 6.8935 3.94014C5.80643 3.88516 4.74312 3.60211 3.7726 3.10938C2.80208 2.61666 1.94606 1.92527 1.26016 1.08014C1.01959 1.50025 0.893176 1.97602 0.893496 2.46014C0.892643 2.90973 1.00298 3.35255 1.21467 3.74918C1.42636 4.14582 1.73284 4.48395 2.10683 4.7335C1.67215 4.72163 1.24676 4.605 0.866829 4.39348V4.42681C0.870089 5.05676 1.09082 5.66623 1.4917 6.1521C1.89259 6.63803 2.449 6.97056 3.06683 7.0935C2.829 7.16583 2.58208 7.20403 2.3335 7.20683C2.16142 7.20483 1.98978 7.18916 1.82016 7.16016C1.9961 7.70203 2.33658 8.17556 2.79421 8.5149C3.25185 8.85423 3.80388 9.0425 4.3735 9.0535C3.41163 9.8103 2.22408 10.2234 1.00016 10.2268C0.777323 10.2276 0.554656 10.2142 0.333496 10.1868C1.58312 10.9936 3.03937 11.422 4.52683 11.4202C5.55329 11.4308 6.57156 11.2368 7.52223 10.8495C8.4729 10.4622 9.33683 9.88936 10.0636 9.1645C10.7904 8.43956 11.3655 7.5771 11.7552 6.62743C12.145 5.67776 12.3415 4.65996 12.3335 3.63348C12.3335 3.52014 12.3335 3.40014 12.3335 3.28014C12.8566 2.89002 13.3078 2.41176 13.6668 1.86681Z'
				fill='currentColor'
			/>
		</svg>
	);
};

TwitterIcon.propTypes = {
	className: PropTypes.string,
};

TwitterIcon.defaultProps = {
	className: "",
};

export default TwitterIcon;
